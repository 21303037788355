import { StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { amplitude } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga } from '@helpers/ga'
import { ModalContextProvider } from '@modals/core/provider'
import { NextUIProvider } from '@nextui-org/system'
import { CookieAlert } from '@share/cookie-alert'

import { StaticRoutes } from './static-routes'

amplitude.init()
facebookPixel.init()
ga.init()

hydrateRoot(
  document.getElementById('app')!,
  <StrictMode>
    <NextUIProvider>
      <ModalContextProvider>
        <BrowserRouter>
          <StaticRoutes />
        </BrowserRouter>
        <CookieAlert />
      </ModalContextProvider>
    </NextUIProvider>
  </StrictMode>,
)
