import { Route, Routes } from 'react-router-dom'
import { StaticContext } from '@helpers/static-context'
import { LandingLayout } from '@layouts/landing-layout'
import { MainLayout } from '@layouts/main-layout'
import {
  ContactsPage,
  FaqPage,
  MainPage,
  TermsAndConditionsPage,
} from '@pages/static'

export const StaticRoutes = () => {
  return (
    <StaticContext.Provider value>
      <Routes>
        <Route element={<LandingLayout />}>
          <Route path="/" element={<MainPage />} />
        </Route>

        <Route path="/" element={<MainLayout />}>
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
        </Route>
      </Routes>
    </StaticContext.Provider>

  )
}
